<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<!-- <sm-card title="筛选查询" noHeader icon="el-icon-search" style="height: 80px">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="查看企业：">
							<el-select v-model="searchParm.ent_id" clearable :popper-append-to-body="false" placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card> -->
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据通讯状态一览表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px">
							<!-- <el-table-column prop="projectId" align="center" label="项目id" show-overflow-tooltip></el-table-column> -->
							<el-table-column prop="projectName" align="center" label="项目名称"
								show-overflow-tooltip width="320"></el-table-column>
							<el-table-column prop="id" align="center" label="测点ID"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="machineName" align="center" label="机器名称"
								show-overflow-tooltip></el-table-column>
							<!-- <el-table-column prop="machineId" align="center" label="机器id" show-overflow-tooltip></el-table-column> -->
							<el-table-column prop="name" align="center" label="测点位置"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="sn" align="center" label="节点SN"
								show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="电量监控"
								show-overflow-tooltip>
								<template slot-scope="scope">
									<div class="types">
										{{scope.row.power?scope.row.power:'-'}}
									</div>
								</template>
								</el-table-column>
							<el-table-column align="center" label="节点通讯状态" show-overflow-tooltip>
								<template slot-scope="scope">
									<div class="types">
										<div class="dianBox" :class="setcolor(scope.row.networkStatus)">
											<div class="xiaodianBox" :class="setcolor2(scope.row.networkStatus)"></div>
										</div>
										<div :class="setcolor3(scope.row.networkStatus)">{{setType(scope.row.networkStatus)}}</div>
									</div>
								</template>

							</el-table-column>
							<el-table-column prop="gatewaySn" align="center" label="网关SN"
								show-overflow-tooltip></el-table-column>
							<!-- <el-table-column prop="gatewayId" align="center" label="网关ID" show-overflow-tooltip></el-table-column> -->
							<el-table-column align="center" label="网关网络状态"
								show-overflow-tooltip>
								
								<template slot-scope="scope">
									<div class="types">
										<div class="dianBox" :class="setcolor(scope.row.gatewayNetworkStatus)">
											<div class="xiaodianBox" :class="setcolor2(scope.row.gatewayNetworkStatus)"></div>
										</div>
										<div :class="setcolor3(scope.row.gatewayNetworkStatus)">{{setType(scope.row.gatewayNetworkStatus)}}</div>
									</div>
								</template>
								
								</el-table-column>
							<el-table-column prop="serverNode" align="center" label="服务器"
								show-overflow-tooltip></el-table-column>

							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text">-</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.currentPageNo" :page-size="searchParm.pageSize"
						layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
				</div>
			</div>
		</div>
		<insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
	</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	import insert from './insert.vue';
	export default {
		components: {
			smCard,
			insert
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: []
				},
				searchParm: {
					pageSize: 20,
					currentPageNo: 1
				},
				showInsertDialog: false,
				insertObj: {},
				showEditDialog: false,
				editObj: {},
				showCopyInsertDialog: false,
				copyInsertObj: {},

				ents: []
			};
		},
		created() {
			this.getEnts();
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			//获取企业列表
			getEnts() {
				this.$get('/backend-api/sys/ent/list', {
					size: 99
				}).then(res => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					pageSize: 20,
					currentPageNo: 1
				};
				this.list();
			},
			//列表数据
			list() {
				this.$get('/backend-api/zhihuanApi/list', this.searchParm).then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开新增弹窗
			insert() {
				this.insertObj['type'] = 1;
				this.showInsertDialog = true;
			},
			//打开新增弹窗
			editName(row) {
				this.insertObj['row'] = row;
				this.insertObj['type'] = 2;
				this.showInsertDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = row;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//删除
			remove(row) {
				this.$confirm('是否确认删除？', '删除绑定', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$post('/backend-api/eqp/set/unit/meas/zhihuan/delete', {
							id: row.id
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},
			setType(e) {
				switch (e) {
					case 'ONLINE':
						return '在线'
					case 'INACTIVATED':
						return '未激活'
					case 'OFFLINE':
						return '离线'
					case 'UNKNOWN':
						return '未知'
				}
			},
			setcolor(e) {
				switch (e) {
					case 'ONLINE':
						return 'bg1'
					case 'INACTIVATED':
						return 'bg3'
					case 'OFFLINE':
						return 'bg5'
					case 'UNKNOWN':
						return 'bg3'
				}
			},
			setcolor2(e) {
				switch (e) {
					case 'ONLINE':
						return 'bg2'
					case 'INACTIVATED':
						return 'bg4'
					case 'OFFLINE':
						return 'bg6'
					case 'UNKNOWN':
						return 'bg4'
				}
			},
			setcolor3(e) {
				switch (e) {
					case 'ONLINE':
						return 'color1'
					case 'INACTIVATED':
						return 'color2'
					case 'OFFLINE':
						return 'color3'
					case 'UNKNOWN':
						return 'color2'
				}
			},

		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';

	.types {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dianBox {
		width: 10px;
		height: 10px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 8px;

		.xiaodianBox {
			width: 4px;
			height: 4px;
			border-radius: 50%;
		}
	}


	.bg1 {
		background: rgba(0, 222, 107, 0.3);
	}

	.bg2 {
		background: #00de6b;
	}


	.bg3 {
		background: rgba(184, 184, 184, 0.3);
	}

	.bg4 {
		background: #b8b8b8;
	}

	.bg5 {
		background: rgba(255, 0, 0, 0.3);
	}

	.bg6 {
		background: #FF0000;
	}
	
	.color1 {
		color: #00de6b;
	}
	
	.color2 {
		color: #b8b8b8;
	}
	
	
	.color3 {
		color: #FF0000;
	}
</style>